import logo from './anchor-park-logo-small.png';
import photo from './anchor-park-image.png';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" max-width="80%" height="auto" />
        <p>
          A unique opportunity to live in your own brand new home <br>
          </br>built by dedicated local craftsmen in an idyllic Devon village
        </p>
        
        <p>contact <a href="mailto:anchorparkhomes@gmail.com">anchorparkhomes@gmail.com</a></p>
        <img src={photo} alt="photo" max-width="80%" height="auto"/>
        <a
          className="App-link"
          href="https://anchorparkhomes.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Anchor Park Homes
        </a>
      </header>
    </div>
  );
}

export default App;
